import {
  ALARM_STATUSES,
  BATTERY_STATUSES,
  GPS_STATUSES
} from '@/modules/objects/compositions/objectStatus';
import {
  DEFAULT_FILTER_TYPES,
  defaultFilters
} from '@/compositions/filter.helpers';
import { useTypes } from '@/modules/object-types/compositions/types';
import { ColorNames } from '@/compositions/map/utils/data';
import {
  NOTIFICATION_TAGS,
  NOTIFICATION_TYPES
} from '@/modules/notifications/compositions/notifications';
import { toISOStringWithTimezone } from '@/utils';
import NO_FILTER_ITEM_VALUE from 'core-js/internals/array-includes';

export const NOTIFICATION_LIST_FILTER = 'NOTIFICATION_LIST_FILTER';

const notificationTagItems = [
  {
    name: 'Message',
    value: NOTIFICATION_TYPES.MESSAGE
  },
  {
    name: 'Notice',
    value: NOTIFICATION_TYPES.NOTICE
  },
  {
    name: 'Alert',
    value: NOTIFICATION_TYPES.ALERT
  },
  {
    name: 'Alert triggered',
    value: NOTIFICATION_TYPES.ALERT_TRIGGERED
  },
  {
    name: 'Alarm',
    value: NOTIFICATION_TYPES.ALARM
  },
  {
    name: 'Triggered',
    value: NOTIFICATION_TYPES.TRIGGERED
  },
  {
    name: 'Dismissed',
    value: NOTIFICATION_TYPES.DISMISSED
  }
];

export function useNotificationFilters() {
  const { list: types } = useTypes();

  const FILTER_TYPES = {
    FAVOURITES: 'FAVOURITES',
    MUTED: 'MUTED',
    STATUS: 'STATUS',
    BATTERY: 'BATTERY',
    ALARM: 'ALARM',
    GPS: 'GPS',
    TYPE: 'TYPE',
    COLORS: 'COLORS',
    TAGS: 'TAGS',
    DATE_FROM: 'DATE_FROM',
    DATE_TO: 'DATE_TO'
  };

  const filters = [
    {
      header: 'Objects',
      ...defaultFilters[DEFAULT_FILTER_TYPES.FAVOURITES],
      filterQuery: value =>
        value === null
          ? {}
          : {
              object: {
                favourite: {
                  equalTo: value
                }
              }
            }
    },
    {
      ...defaultFilters[DEFAULT_FILTER_TYPES.MUTED],
      filterQuery: value =>
        value === null
          ? {}
          : {
              object: {
                muted: {
                  equalTo: value
                }
              }
            }
    },
    {
      ...defaultFilters[DEFAULT_FILTER_TYPES.STATUS],
      filterQuery: value =>
        value === null
          ? {}
          : {
              object: {
                enabled: {
                  equalTo: value
                }
              }
            }
    },
    {
      id: FILTER_TYPES.BATTERY,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Battery',
        items: [
          {
            name: 'Full',
            value: BATTERY_STATUSES.FULL
          },
          {
            name: 'Low',
            value: BATTERY_STATUSES.LOW
          },
          {
            name: 'DC',
            value: BATTERY_STATUSES.DC
          },
          {
            name: 'Unknown',
            value: BATTERY_STATUSES.UNKNOWN
          }
        ]
      },
      filterQuery: valueArray =>
        !valueArray.length
          ? {}
          : {
              object: {
                objectPropertiesConnection: {
                  some: {
                    key: {
                      equalTo: 'statusesBatteryType'
                    },
                    value: {
                      in: valueArray?.map(v => v.value)
                    }
                  }
                }
              }
            }
    },
    {
      id: FILTER_TYPES.ALARM,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Alarm',
        items: [
          {
            name: 'Off',
            value: ALARM_STATUSES.OFF
          },
          {
            name: 'On',
            value: ALARM_STATUSES.ON
          },
          {
            name: 'Triggered',
            value: ALARM_STATUSES.TRIGGERED
          }
        ]
      },
      filterQuery: valueArray =>
        !valueArray.length
          ? {}
          : {
              object: {
                objectPropertiesConnection: {
                  some: {
                    key: {
                      equalTo: 'statusesAlarm'
                    },
                    value: {
                      in: valueArray?.map(v => v.value)
                    }
                  }
                }
              }
            }
    },
    {
      id: FILTER_TYPES.GPS,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'GPS',
        items: [
          {
            name: 'Off',
            value: GPS_STATUSES.OFF
          },
          {
            name: 'Fixed',
            value: GPS_STATUSES.FIXED
          },
          {
            name: 'Not fixed',
            value: GPS_STATUSES.NOT_FIXED
          }
        ]
      },
      filterQuery: valueArray =>
        !valueArray.length
          ? {}
          : {
              object: {
                objectPropertiesConnection: {
                  some: {
                    key: {
                      equalTo: 'statusesGps'
                    },
                    value: {
                      in: valueArray?.map(v => v.value)
                    }
                  }
                }
              }
            }
    },
    {
      id: FILTER_TYPES.TYPE,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Type',
        items: types.value
      },
      filterQuery: valueArray =>
        !valueArray.length
          ? {}
          : {
              object: {
                schemaId: {
                  in: valueArray?.map(v => v.id)
                }
              }
            }
    },
    {
      ...defaultFilters[DEFAULT_FILTER_TYPES.COLORS],
      filterQuery: valueArray => {
        const values = valueArray?.map(v => v.value.toLowerCase());

        if (values.includes(ColorNames.default)) {
          values.push('Default');
        }

        return !valueArray.length
          ? {}
          : {
              object: {
                objectPropertiesConnection: {
                  some: {
                    key: {
                      equalTo: 'currentStateColor'
                    },
                    value: {
                      in: values
                    }
                  }
                }
              }
            };
      }
    },
    {
      id: FILTER_TYPES.TAGS,
      header: 'Notifications',
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Tags',
        items: notificationTagItems
      },
      filterQuery: valueArray =>
        !valueArray.length
          ? {}
          : {
              or: valueArray?.map(v => {
                let tag = '';
                if (v.value) {
                  tag = NOTIFICATION_TAGS[v.value];
                } else {
                  const index = notificationTagItems.findIndex(
                    item => item.name === v
                  );
                  if (index > -1) {
                    tag = NOTIFICATION_TAGS[notificationTagItems[index].value];
                  } else {
                    tag = v;
                  }
                }
                return {
                  tags: {
                    contains: ['application', 'monitor'].concat(tag)
                  }
                };
              })
            }
    },
    {
      id: FILTER_TYPES.DATE_FROM,
      default: '',
      component: {
        name: 'date',
        label: 'From'
      },
      filterQuery: value =>
        !value
          ? {}
          : {
              createdAt: {
                greaterThanOrEqualTo: toISOStringWithTimezone(new Date(value))
              }
            }
    },
    {
      id: FILTER_TYPES.DATE_TO,
      default: '',
      component: {
        name: 'date',
        label: 'To'
      },
      filterQuery: value =>
        !value
          ? {}
          : {
              createdAt: {
                lessThanOrEqualTo: toISOStringWithTimezone(
                  new Date(
                    new Date(value).setDate(new Date(value).getDate() + 1)
                  )
                )
              }
            }
    }
  ];

  const applyFilter = filter => {
    const filterMap = filters.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    return Object.keys(filter).reduce((acc, cur) => {
      if (!acc.and) acc.and = [];
      const filterQuery = filterMap[cur].filterQuery(filter[cur]);
      if (Object.keys(filterQuery).length) {
        acc.and.push(filterQuery);
      }
      return acc;
    }, {});
  };

  return {
    filters,
    applyFilter
  };
}
